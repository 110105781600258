$(function() {

	/**
	 *  Preload
	 */

	$(window).on('load', function() {
		$('.preload').removeClass('preload');
	});


	/**
	 * External link
	 */

    targetBlank();


    /*
        PDF icon
     */

    pdfLink();


     /*
     	Quicknav - search
      */

    $(document).on('click', '#quicknavSearch', function(event) {
     	event.preventDefault();

     	$(this).parent('.quicknav__search').toggleClass('quicknav__search--open');

        $('#s').focus();
    });


    /*
        Nav
     */

    $(document).on('mouseenter mouseleave', '.menu > li', function() {
        $(this).toggleClass('open');
    });


     /*
     	Form
      */

    // Open form
    $(document).on('click', '.triggerForm', function() {

    	// Close all other form boxes
    	$('.closeForm').trigger('click');

    	// Open this box and hide open button
    	$(this).next('.box__form').slideDown();
    	$(this).css('visibility', 'hidden');

    });

    // Close form
    $(document).on('click', '.closeForm', function() {

    	$container = $(this).parent('.box__form');

    	// Close this box and show open button
    	$container.slideUp();
    	$container.siblings('.triggerForm').css('visibility', 'visible');

    });

    // Trigger mail from another button
    $(document).on('click', '.sendForm', function() {
    	$(this).siblings('.wpcf7').find('.wpcf7-submit').trigger('click');

        $(this).addClass('submitting');
    });

    // Mail send logic
    // doesn't work
    /*
	if( $('.wpcf7').length > 0 ) {

	  $(document).on('spam.wpcf7', function () {
	    console.log('submit.wpcf7 triggered!');
	  });

	  $(document).on('invalid.wpcf7', function () {
	    console.log('invalid.wpcf7 triggered!');
	  });

	  $(document).on('mailsent.wpcf7', function () {
	    console.log('mailsent.wpcf7 triggered!');
	  });

	  $(document).on('mailfailed.wpcf7', function () {
	    console.log('mailfailed.wpcf7 triggered!');
	  });
	}
    */


    /*
        Mobile Nav
     */

    $(document).on('click', '#triggerNav', function() {

        $('.nav').toggleClass('nav--active');

    });


    // Toggle Subnav
    $(document).on('click', '.menu-item-has-children > a', function() {
        // Reset all classes only when not same (allow toggle)
        if ( !$(this).siblings('.sub-menu').hasClass('active') ) {
            $('.sub-menu').removeClass('active');
        }

        // Toggle class on current
        $(this).siblings('.sub-menu').toggleClass('active');

        return false;
    });


     /*
        Google Maps
     */

    if ( $('#map').length ) {
        render_map( $('#map') );
    }


	/**
	 * Responsive
	 */

    /*
	browserWidth = $(window).innerWidth();
	browserHeight = $(window).innerHeight();

	$(window).on('resize', function() {
		browserWidth = $(window).innerWidth();
		browserHeight = $(window).innerHeight();

		if ( browserWidth <= 900 ) {
			// Do something
		} else {
			// Do something
		}
	});
    */

   // media query event handler
    if (matchMedia) {
        var mq = window.matchMedia("(min-width: 980px)");
        mq.addListener(WidthChange);
        WidthChange(mq);
    }



    /**
     *  PJAX
     */

    new Pjax({
        // All links have a internal class, but PJAX doesn't see them after ajax request
        elements: ".nav a, .quicklink a, .quicknav a, .header__logo, .grid__item, .wc-forward", // Select all internal links - don't hardcode
        //selectors: ["#main"] // this creates multiple header
        //selectors: ["title", ".main", ".nav", ".front__image"] // replace also body
        selectors: ["title", "#wrapper"], // #main
    })


    /*
    $(document).pjax('.nav a, .quicklink a, .header__logo, .grid__item, .added_to_cart', "#wrapper", {
        scrollTo: !1,
        dataType: "html",
        timeout: 2e3
    });
    */


});


/*
    Handle external link
 */
function targetBlank() {

    var host = window.location.hostname;
    var domain = host.replace("www.", "");

    // Internal links
    $('a[href="' + domain + '"]').addClass('internal');

    // External links
    $('a[href^="http"]')
        .not('a[href*="' + domain + '"]')
        .attr("target", "_blank");

}


/*
   PDF Link handler
 */
function pdfLink() {

    $("a[href$='.pdf']")
        .attr("target", "_blank")
            .not('.pdf')
            .addClass('pdf')
            .append('<span> (PDF)</span>');

}


/*
    Google Maps
 */
function render_map( $el ) {

    var pos_x = $el.attr('data-lat');
    var pos_y = $el.attr('data-lng');

    // Disable dragging on mobile
    //var isDraggable = $(document).width() > 480 ? true : false;
    var isDraggable = !('ontouchstart' in document.documentElement);

    // Set position
    var location = new google.maps.LatLng(pos_x,pos_y);

    // Map options
    var mapOptions = {
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        zoom: 15,
        center: location,
        scrollwheel: false,
        navigationControl: false,
        mapTypeControl: false,
        scaleControl: false,
        draggable: isDraggable
    };

    // Create map - global variable, let override the settings after
    map = new google.maps.Map( $el[0], mapOptions);

    // Change map to greyscale
    var mapstyle = [{ "stylers": [{ "saturation": -100}, {"lightness": -2 }] }];
    map.setOptions({ styles: mapstyle });

    var pinImage =  new google.maps.MarkerImage( template_url + "/dist/images/map.png"
     );

    // Marker position
    var marker = new google.maps.Marker({
        position: location,
        map: map,
        icon: pinImage,
    });

}


// media query change
function WidthChange(mq) {

    if (mq.matches) {
        //alert('desktop');
        $('.nav').removeClass('nav--active');
    } else {
        //$('#back-to-top').css('opacity', 1);
    }

}

//$(document).pjax('a', '.superwrapper')

// Since Pjax doesn't magically rexecute you previous code each time you load a page, you need to make a simple thing to rexecute appropriate code

function whenDOMReady() {
    //alert('hehe'); // test
    if ( $('#map').length ) {
        render_map( $('#map') );
    }

    var bodyClass = $('#wrapper').data("content");
    $("body").attr("class", bodyClass);

    targetBlank();
    pdfLink();

    var mq = window.matchMedia("(min-width: 980px)");
    mq.addListener(WidthChange);
    WidthChange(mq);

    //$('.main').removeClass('loading');
    $('body').removeClass('preload');
}

whenDOMReady()

document.addEventListener("pjax:send", function() {
//$(document).on('pjax:send', function() {
  //console.log("Event: pjax:send", arguments)
  //$('.main').addClass('loading');
  $('body').addClass('preload');
  //console.log('PJAX send');
})

//document.addEventListener("pjax:success", whenDOMReady); // doesn't trigger

document.addEventListener("pjax:complete", function() {
//$(document).on('pjax:complete', function() {
    setTimeout(function(){
        whenDOMReady();
    }, 300); // wait at least 300ms - animation gets wrecked instead
    //console.log('PJAX Successful');
});

/*
document.addEventListener("DOMContentLoaded", function() {
  var pjax = new Pjax({
    selectors: ["#main"],
    //selectors: [".body"],
    // currentUrlFullReload: true,
  })
  console.log("Pjax initialized.", pjax)
})
*/